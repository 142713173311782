import {
    CreateButton,
    DatagridConfigurable,
    DeleteWithConfirmButton,
    EditButton,
    List,
    ListProps,
    NumberField,
    TextField,
    TopToolbar
} from 'react-admin';
import ActionsColumn from "../ActionsColumn";
import {RESOURCE_FAVOURITE_LIST} from "../../provider/restProvider";
import React from "react";
import {FavouriteListEdit} from "./FavouriteListEdit";
import {PostPagination} from '../PostPagination';
import SelectColumnsButtonAutoSave from "../SelectColumnsButtonAutoSave";

const FavouriteActions = () => (
  <TopToolbar>
      <CreateButton/>
      <SelectColumnsButtonAutoSave/>
  </TopToolbar>
);


const ListDetail = (record) => {
  return <FavouriteListEdit
    resource={RESOURCE_FAVOURITE_LIST} id={record.id}
    syncWithLocation={false}
  />;
};


export const FavouriteList: React.FC<ListProps> = props => {

  return (
    <List title="Favorite Lists"
          {...props}
          sort={{field: 'name', order: 'ASC'}}
          actions={<FavouriteActions/>}
          perPage={50}
          pagination={<PostPagination/>}
    >

        <DatagridConfigurable
            rowClick="expand"
            expandSingle={true}
            // optimized={true}
            expand={<ListDetail/>}
            bulkActionButtons={false}>

            <NumberField source="id" label={'ID'} sortable={false}/>
            <TextField source="name" sortable={false}/>
            <TextField source="lastModifiedDate" label={'Last Modified'} sortable={false}/>
            <TextField source="fieldManagerUpdatedName" label={'Updated by'} sortable={false}/>
            <TextField source="creationDate" label={'Created'} sortable={false}/>
            <TextField source="fieldManagerCreatedName" label={'Created by'} sortable={false}/>

            <ActionsColumn label={'Actions'}>
                <EditButton label=""/>
                <DeleteWithConfirmButton label=""/>
            </ActionsColumn>
        </DatagridConfigurable>

    </List>
  );
};
