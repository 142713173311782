import * as React from 'react';
import {useState} from 'react';
import {
    Datagrid,
    Form,
    ListContextProvider,
    ListView,
    SelectInput,
    TextField,
    useGetList,
    useListContext
} from 'react-admin';
import {RESOURCE_CANDIDATES} from "../../provider/restProvider";
import LinkField from "../LinkField";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {ALL_2099, dashboardClasses, featureChoices, typographyProps} from "./Dashboard";

const FilterForm = () => {
    const {
        filterValues,
        setFilters,
    } = useListContext();

    return (
        <Box display={'flex'} justifyContent={'space-between'} m={0} width={'100%'}>
            <Typography {...typographyProps}>
                Candidates Available Soon
            </Typography>

            <Form onSubmit={() => {
            }}>
                <SelectInput
                    label={'Available within'}
                    source="creationDate"
                    margin={'dense'}
                    choices={featureChoices}
                    sx={dashboardClasses.selectInput}
                    defaultValue={filterValues.availabilityDate.fieldValue}
                    onChange={(event) => {
                        setFilters({
                            availabilityDate: {
                                fieldName: "availabilityDate",
                                fieldValue: event.target.value as string,
                                condition: "<="
                            }
                        }, {creationDate: true})
                    }}
                />
            </Form>
        </Box>
    )
};

export const AvailableCandidates = () => {
    const [filter, setFilter] = useState({
        availabilityDate: {
            "fieldName": "availabilityDate",
            "fieldValue": ALL_2099,
            "condition": "<="
        },
    });
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5);

    const sort = {field: 'availabilityDate', order: 'ASC'};
    const {data, total, isLoading} = useGetList(RESOURCE_CANDIDATES, {
        filter,
        pagination: {page, perPage},
        sort,
        meta: {
            useSmallModel: true
        }
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const setFilters = (newFilters) => {
        setFilter({...filter, ...newFilters})
    };

    // @ts-ignore
    return (<ListContextProvider value={{
        data,
        total,
        page,
        perPage,
        setPerPage,
        setPage,
        filterValues: filter,
        setFilters,
        sort,
        defaultTitle: ' '
    }}>
        <ListView
            exporter={false}
            hasCreate={false}
            actions={<FilterForm/>}
        >
            <Datagrid bulkActionButtons={false}>
                <LinkField source="id" label={"Candidate"} resource={RESOURCE_CANDIDATES}>
                    <TextField source="candidateName" sortable={false}/>
                </LinkField>
                <TextField source="availabilityDate" sortable={false}/>
                <TextField source="availabilityDateLastUpdated" label={'Avail Last Updated'} sortable={false}/>
            </Datagrid>
        </ListView>
    </ListContextProvider>);

};
