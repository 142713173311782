import {Layout} from '@react-admin/ra-enterprise';
import * as React from "react";
import {AppLocationContext,} from "@react-admin/ra-navigation";
import {AppMenu, MyUserMenu} from "./AppMenu";
import {AppBar,} from 'react-admin';
import {ProfileProvider} from "./components/Users/ProfileEdit";

const MyAppBar = props =>
    <AppBar {...props}
            sx={{
              borderRadius: '4px'
            }}
            userMenu={<MyUserMenu/>}/>;

export const AppLayout = (props) => {
  return <AppLocationContext>
    <ProfileProvider>
      <Layout
          {...props}
          menu={AppMenu}
          appBar={MyAppBar}
          breadcrumb={<></>}
      />
    </ProfileProvider>
  </AppLocationContext>;
};
