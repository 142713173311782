import * as React from "react";
import PropTypes from "prop-types";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import {Button, Identifier, useNotify, useRefresh, useUnselectAll,} from "react-admin";

import api from "../../provider/api";
import {AxiosResponse} from "axios";
import {AddProspectProps} from "./JobEdit";

const noSelection: Identifier[] = [];

const addProspect = async (jobId, selectedIds) => {
  const jobResponseObject = selectedIds.map((selectedCandidateId) => {
    return {
      id: 0,
      candidateName: "",
      candidateId: selectedCandidateId,
      status: "Selected as Prospect",
      jobId,
      jobTitle: "Job One",
      prospectStatusId: 1,
    };
  });
  try {
    const response: AxiosResponse = await api.jobresponses.create(jobResponseObject);
    if (response.status === 200) {
      const { data } = response;
      return Promise.resolve({
        data,
      });
    } else {
      return Promise.reject(`Api returned ${response.status}`);
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

const AddProspectButton: React.FC<AddProspectProps> = ({
  selectedIds = noSelection,
  jobId,
                                                         resource
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(resource);
  return (
    <Button
      label="Add prospects"
      onClick={() => {
        addProspect(jobId, selectedIds);
        setTimeout(() => {
          notify('Candidate added as prospect', {type: 'info', undoable: false});
          unselectAll();
          refresh();
        }, 500);
      }}
      disabled={false}
    >
      <AssignmentTurnedInIcon />
    </Button>
  );
};

AddProspectButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default AddProspectButton;
