import {PublicFieldProps} from "ra-ui-materialui/src/field/types";
import * as React from 'react';
import {Button, useRecordContext} from "react-admin";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

interface Props extends PublicFieldProps {
    onClick: Function,
    label: string,
}



const ParseCVButton: React.FC<Props> = ({source, label, onClick, className}) => {
    const record = useRecordContext();
    return record && record[source] && record.documentType === 'cv' ? (
            <Button
                label={label}
                onClick={() => onClick({...record, fileType: 1})}
                className={className}>
                <DocumentScannerIcon/>
            </Button>
    ) : null;
};

export default ParseCVButton;

