import {Button, Datagrid, List, TextField, useRecordContext,} from "react-admin";
import DialogActions from '@mui/material/DialogActions';
import IconClose from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {DialogContent} from '@mui/material';

import React from "react";
import {FieldProps} from "ra-ui-materialui/src/field/types";
import {RESOURCE_FAVOURITE_LIST} from "../../../provider/restProvider";

export interface FavouriteListSelectionDialogProps {
  onClose: () => void
  onSelect: (record: any) => void
}

interface SelectButtonProps extends FieldProps {
  onSelect: (record: any) => void
}

const SelectButton: React.FC<SelectButtonProps> = ({onSelect}) => {
  const record = useRecordContext();
  return <Button onClick={() => onSelect(record)} label={'Select'}/>;
}

const FavouriteListSelectionDialog: React.FC<FavouriteListSelectionDialogProps> = ({onClose, onSelect}) => {

  return (<Dialog maxWidth="lg" onClose={onClose} open>
    <DialogTitle>Select List</DialogTitle>
    <DialogContent>

      <List title=""
            resource={RESOURCE_FAVOURITE_LIST}
            // sort={{field: 'firstName', order: 'ASC'}}
            perPage={100}
            pagination={false}
            exporter={false}
            hasCreate={false}
            disableSyncWithLocation={true}
      >
        <Datagrid optimized={true} bulkActionButtons={false}>
          <TextField source={"name"} sortable={false}/>
          <SelectButton onSelect={onSelect}/>
        </Datagrid>
      </List>

    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" label="Close">
        <IconClose/>
      </Button>
    </DialogActions>
  </Dialog>);
}

export default FavouriteListSelectionDialog;
