import {RESOURCE_CANDIDATES, RESOURCE_JOB_RESPONSES,} from "../../provider/restProvider";
import {
  Datagrid,
  Link,
  ListContextProvider,
  ListView,
  TextField,
  useGetList,
  useListContext,
  useRecordContext,
  useRefresh
} from "react-admin";
import React, {useEffect, useState} from "react";
import {MAX_RECORD_PER_PAGE} from "../../provider/constants";
import {FieldProps} from "ra-ui-materialui/src/field/types";
import ActionsColumn from "../ActionsColumn";
import SendNotificationButton from "./SendNotificationButton";
import {AddProspectProps} from "./JobEdit";
import CandidateMessageButton from "../Candidate/CandidateMessageButton";
import {isNotEmpty} from "../../provider/UtilityFunctions";
import DownloadButton from "../Candidate/DownloadButton";
import CircularProgress from '@mui/material/CircularProgress';
import RemoveMultipleButton from "./RemoveMultipleButton";
import Box from "@mui/material/Box";
import {candidateRowStyle} from "../Candidate/CandidateCommon";
import {Identifier} from "ra-core";
import {JobResponseUpdateButton} from "./JobResponseUpdateButton";
import {ListControllerResult} from "ra-core/dist/cjs/controller/list/useListController";


const classes = {
  fullWidth: {
    width: "100%",
  },
  inlineBlock: {
    display: "inline-block",
    marginRight: "0.5rem",
    verticalAlign: "middle",
  },
  selectInputStyle: {
    display: "inline-block",
    marginRight: "0.5rem",
    minWidth: "280px",
    "& .MuiSelect-filled.MuiSelect-filled": {
      marginTop: "5px",
    },
  },
};

const CandidateLinkField: React.FC<FieldProps> = () => {
  const record = useRecordContext();
  return <Link to={`/${RESOURCE_CANDIDATES}/${record.candidateId}`}>
    <TextField source="candidateName" sortable={false}/>
  </Link>;
};

const BulkActionButtons: React.FC<AddProspectProps> = ({
                                                         jobId,
                                                         selectedIds,
                                                         resource
                                                       }) => {

  const {data, onUnselectItems} = useListContext();
  const [selectedCandidateIds, setSelectedCandidateIds] = useState([]);

  useEffect(() => {
    const _selectedCandidateIds = []
    selectedIds.map((jobResponseId) => {
      const jResponse = data.find(jobResponse => jobResponse.id === jobResponseId);
      if (jResponse) {
        _selectedCandidateIds.push(jResponse.candidateId);
      }
    });
    setSelectedCandidateIds(_selectedCandidateIds);
  }, [data, selectedIds]);


  if (jobId && selectedIds && data) {
    return <>
      <RemoveMultipleButton
          jobId={jobId}
          selectedIds={selectedIds}
          clearSelection={() => onUnselectItems()}
      />

      <SendNotificationButton resource={RESOURCE_CANDIDATES}
                              jobId={jobId}
                              selectedIds={selectedCandidateIds}
      />
    </>;

  } else {
    return <></>;
  }
}


const StartChatActionButton = () => (
    <CandidateMessageButton label={""}/>
);

const JobCandidates = ({jobId, ...rest}) => {
  const jobRecord = useRecordContext();
  const refresh = useRefresh();
  const projectId = isNotEmpty(jobRecord?.projectId)
      ? jobRecord?.projectId
      : undefined;

  const [selectedIds, setSelectedIds] = useState<Identifier[]>([]);
  const [filter, setFilter] = useState({
    jobId: {
      fieldName: "jobId",
      fieldValue: '' + jobId,
      condition: "==",
    },
    projectId,
  });

  const onSelect = (val) => {
    setSelectedIds(val);
  }
  const onToggleItem = (item) => {
    if (selectedIds.includes(item)) {
      //Remove
      setSelectedIds(selectedIds.filter(id => id !== item));
    } else {
      //Insert
      setSelectedIds([...selectedIds, item]);
    }
  }
  const onUnselectItems = () => {
    setSelectedIds([]);
  }

  const sort = {field: "id", order: "ASC"};
  const {data, total, isLoading} = useGetList(RESOURCE_JOB_RESPONSES, {
    filter,
    pagination: {page: 1, perPage: MAX_RECORD_PER_PAGE},
    sort,
  });

  // @ts-ignore
  const value: ListControllerResult = {
    data, total, page: 1, perPage: MAX_RECORD_PER_PAGE, filterValues: filter, sort,
    selectedIds,
    onSelect,
    onToggleItem,
    onUnselectItems,
  };

  return (
      <Box sx={{
        '& .list-page': {
          marginTop: '-30px'
        }
      }}>
        <ListContextProvider value={value}>
          <ListView
              title={" "}
              pagination={false}
              exporter={false}
              hasCreate={false}
              actions={<></>}
          >
            {isLoading ? (
                <Box display={"flex"} justifyContent={"center"} margin={"20px"}>
                  {" "}
                  <CircularProgress/>{" "}
                </Box>
            ) : (
                <Datagrid
                    empty={<Box margin={"10px"}> No candidate found </Box>}
                    rowStyle={candidateRowStyle}
                    bulkActionButtons={
                      <BulkActionButtons
                          jobId={jobId}
                      />
                    }
                >
                  <CandidateLinkField source={"candidateName"} sortable={false}/>
                  <TextField source="status" sortable={false}/>
                  <TextField source="jobTitle" sortable={false}/>
                  <TextField
                      source="clientContactName"
                      label={"Contact"}
                      sortable={false}
                  />
                  <ActionsColumn>
                    <DownloadButton source="rtrDocumentUrl" label=""/>
                    <StartChatActionButton/>
                    <JobResponseUpdateButton jobRecord={jobRecord}/>
                  </ActionsColumn>
                </Datagrid>
            )}
          </ListView>
        </ListContextProvider>
      </Box>
  );
};

export default JobCandidates;
