import React from "react";
import {Login, LoginForm} from "react-admin";
import Logo from "../assets/LeftLogo.png";
import {Link} from "react-router-dom";

const HRLogin = () => {
    return (
        <Login
            title={"Harmoniq HR Portal"}
            backgroundImage={Logo}
        >
            <LoginForm/>
            <p
                style={{
                    textAlign: "center",
                    fontSize: "0.8rem",
                    textDecoration: "underline",
                    cursor: "pointer",
                }}
            >
                <Link to="/enter-email">Forgot password ?</Link>
            </p>
        </Login>
    );
};

export default HRLogin;
