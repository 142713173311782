import DialogActions from '@mui/material/DialogActions';
import IconClose from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {DialogContent} from '@mui/material';
import {Button, Datagrid, downloadCSV, TextField, useRecordContext} from "react-admin";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/reducers/initialState";
import {RESOURCE_CANDIDATES} from "../../provider/restProvider";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {FieldProps} from "ra-ui-materialui/src/field/types";
import {clearBasket, removeFromBasket} from "../../redux/actions/basketActions";
import AddToContactFavoriteButton from "./AddToContactFavoriteButton";
import GetAppIcon from '@mui/icons-material/GetApp';
import jsonExport from 'jsonexport/dist';
import moment from "moment";

export interface CandidateBasketDialogProps {
  onClose: () => void
}

const RemoveButton: React.FC<FieldProps> = () => {
  const record = useRecordContext();
  const dispatch = useDispatch();

  return <Button onClick={() => dispatch(removeFromBasket(RESOURCE_CANDIDATES, [record.id]))}>
    <RemoveCircleIcon fontSize={'small'}/>
  </Button>;
}

const CandidateBasketDialog: React.FC<CandidateBasketDialogProps> = ({onClose}) => {
  const dispatch = useDispatch();
  const [Ids, setIds] = useState([]);
  const data = useSelector((state: RootState) => state.basket[RESOURCE_CANDIDATES]);

  useEffect(() => {
    if (data == undefined || data.length == 0) {
      onClose();
    } else {
      setIds(data.map(item => item.id));
    }

  }, [data]);

  return <Dialog maxWidth="lg" onClose={onClose} open>
    <DialogTitle>Candidates Basket</DialogTitle>
    <DialogContent>

      <Datagrid
          data={data}
          total={data.length}
          optimized={true}
      >
        <TextField source="firstName" sortable={false}/>
        <TextField source="lastName" sortable={false}/>
        <TextField source="nickName" sortable={false}/>

        <RemoveButton/>

      </Datagrid>

    </DialogContent>
    <DialogActions style={{justifyContent: 'space-between'}}>
      <AddToContactFavoriteButton selectedIds={Ids} onSuccess={onClose}/>

      <Button color="primary" label="Export"
              onClick={() => {
                jsonExport(data, {
                  // headers: ['id', 'post_id', 'post_title', 'body'],
                }, (err, csv) => {
                  downloadCSV(csv, `candidate-basket-${moment().format('MMM-DD-YYYYTHHmm')}`);
                });
              }}
      >
        <GetAppIcon/>
      </Button>

      <Button color="primary" label="Clear basket"
              onClick={() => {
                dispatch(clearBasket(RESOURCE_CANDIDATES));
              }}
      />

      <Button onClick={onClose} color="primary" label="Close">
        <IconClose/>
      </Button>
    </DialogActions>
  </Dialog>;
}

export default CandidateBasketDialog;
