import {
    Button,
    Datagrid,
    DeleteWithConfirmButton,
    EmailField,
    Empty,
    FieldProps,
    Link,
    List,
    ReferenceManyField,
    TextField,
    TopToolbar,
    useGetOne,
    useNotify,
    useRecordContext,
    useRefresh,
    useUnselectAll,
} from "react-admin";
import React, {useState} from 'react';

import {RESOURCE_CLIENT_CONTACTS, RESOURCE_PROJECT_CONTACTS, RESOURCE_PROJECTS} from "../../provider/restProvider";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ActionsColumn from "../ActionsColumn";
import FavoriteCandidatesDialog from "./FavoriteCandidatesDialog";
import {MAX_RECORD_PER_PAGE} from "../../provider/constants";
import MultipleClientContactSelectionDialog from "./MultipleClientContactSelectionDialog";
import api from "../../provider/api";
import {isEmpty} from "../../provider/UtilityFunctions";
import Box from "@mui/material/Box";
import RefreshIcon from '@mui/icons-material/Refresh';
import LinkField from "../LinkField";
import {PostPagination} from "../PostPagination";

interface Props {
    clientId?: string | number;
    projectId?: string | number;
}

const ListActions = ({clientId}) => {
    const refresh = useRefresh();

    return <TopToolbar>
        <Button
            label="Refresh"
            onClick={() => {
                refresh();
            }}
        >
            <RefreshIcon/>
        </Button>

        <Button
            component={Link}
            to={{
                pathname: `/${RESOURCE_CLIENT_CONTACTS}/create`,
                search: `?clientId=${clientId}`,
            }}
            label="Create"
        >
            <AddIcon/>
        </Button>
    </TopToolbar>;
};


interface CustomEditButtonProps extends FieldProps {
  clientId? : string | number;
  projectId? : string | number;
}

const CustomEditButton: React.FC<CustomEditButtonProps> = ({clientId, projectId}) => {
    const record = useRecordContext();
    let search = '';
    if (clientId) {
        search = `${isEmpty(search) ? '?' : search + '&'}clientId=${clientId}`;
    }
    if (projectId) {
        search = `${isEmpty(search) ? '?' : search + '&'}projectId=${projectId}`;
    }
    return <Button
        component={Link}
        to={{
      pathname: `/${RESOURCE_CLIENT_CONTACTS}/${record.id}`,
      search,
    }}
    label=""
  >
    <EditIcon/>
  </Button>;
}

const FavoriteCandidateButton: React.FC<FieldProps> = () => {
    const record = useRecordContext();
    const [isOpen, setIsOpen] = useState(false);
    const title = `${record?.firstName} ${record?.lastName}'s favorite candidates`
    return <>
        <Button
            label=""
            onClick={() => setIsOpen(true)}>
            <FavoriteBorderIcon/>
        </Button>
        {isOpen &&
            <FavoriteCandidatesDialog clientContactId={record?.id} title={title} onClose={() => setIsOpen(false)}/>}
    </>;
}

const NoContactCustomCreate = ({clientId}) => {
  return <div style={{display:'flex', flexDirection:'column'}}>
    <Empty resource={RESOURCE_CLIENT_CONTACTS} hasCreate={false}/>
      <Button
          component={Link}
          to={{
              pathname: `/${RESOURCE_CLIENT_CONTACTS}/create`,
              search: `?clientId=${clientId}`,
          }}
          label="Create"
      >
          <AddIcon/>
      </Button>
  </div>
}

const AddProjectContacts= ({projectId}) => {
    const [isOpen, setIsOpen] = useState(false);
    const {data, isSuccess: loaded} = useGetOne(RESOURCE_PROJECTS, {id: projectId});
    const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(RESOURCE_CLIENT_CONTACTS);

  return <>
      <Button
          label="Refresh"
          onClick={() => {
              refresh();
          }}
      >
          <RefreshIcon/>
      </Button>
      <Button
          label="Add Contact"
          onClick={() => setIsOpen(true)}>
          <AddIcon/>
      </Button>
      {isOpen && <MultipleClientContactSelectionDialog
          onClose={() => setIsOpen(false)}
          filter={loaded && data.clientId ? {
              clientId: {
                  fieldName: "clientId",
                  fieldValue: '' + data.clientId,
                  condition: "=="
              }
          } : undefined}
          onSelect={async (ids) => {
              try {
          await api.projects.assignContacts(projectId, ids);
          unselectAll();
          refresh();
          setIsOpen(false);
        }catch(err){
          notify('Sorry, failed, try again!', {type: 'warning', undoable: false});
        }
      }}
    />}
  </>;
}

const renderDataGrid = (clientId, projectId) => {

    return <Datagrid bulkActionButtons={false}>
        <TextField source={"firstName"} label={'First Name'} sortable={clientId && true}/>
        <TextField source={"lastName"} label={'Last Name'} sortable={clientId && true}/>
        <TextField source={"jobTitle"} label={'Job Title'} sortable={clientId && true}/>
        <EmailField source={"addresses[0].email"} label={'Email'} sortable={false}/>
        <LinkField phone source={"addresses[0].phone"} label={'Phone'}><TextField source={"addresses[0].phone"}
                                                                                  label={'Phone'}
                                                                                  sortable={false}/></LinkField>
        <EmailField source={"addresses[0].alternativeEmail"} label={'Alt Email'} sortable={false}/>
        <TextField source={"addresses[0].alternativePhone"} label={'Alt Phone'} sortable={false}/>
        <TextField source="addresses[0].address" label={'Address'}/>
        <TextField source="addresses[0].country" label={'Country'}/>
        <TextField source="addresses[0].state" label={'State'}/>
    <TextField source="addresses[0].city" label={'City'}/>

    <TextField source={"fieldManagedUpdatedName"} label="Updated By" sortable={false}/>
    <ActionsColumn>
      <FavoriteCandidateButton/>
      <CustomEditButton clientId={clientId} projectId={projectId}/>
      <DeleteWithConfirmButton label="" redirect={false}/>
    </ActionsColumn>
  </Datagrid>;

}

const renderClientContacts = (clientId) => {
    return <List title=" "
                 resource={RESOURCE_CLIENT_CONTACTS}
                 sort={{field: 'firstName', order: 'ASC'}}
                 disableSyncWithLocation={true}
                 hasCreate={false}
                 hasEdit={true}
                 actions={<ListActions clientId={clientId}/>}
                 empty={<NoContactCustomCreate clientId={clientId}/>}
                 perPage={100}
                 pagination={<PostPagination/>}
                 filter={{
                     clientId: {
                         fieldName: "clientId",
                         fieldValue: '' + clientId,
                         condition: "=="
                     }
                 }}
    >
        {renderDataGrid(clientId, undefined)}
    </List>;

};

const renderProjectContacts = (projectId, clientId) => {

    return <>
        <Box display={'flex'} justifyContent={'flex-end'}>
            <AddProjectContacts projectId={projectId}/>
        </Box>
        <ReferenceManyField
            reference={RESOURCE_PROJECT_CONTACTS}
            target="projectcontacts"
            label=""
            perPage={MAX_RECORD_PER_PAGE}
            fullWidth={true}
            sort={{field: 'firstName', order: 'ASC'}}
        >

            {renderDataGrid(clientId, projectId)}
        </ReferenceManyField>
    </>;
};


export const ClientContactList: React.FC<Props> = ({clientId, projectId}) => {

    const record = useRecordContext();

    if (!clientId && !projectId) {
        return <div> Do not render alone</div>
    } else {
        return projectId ? renderProjectContacts(projectId, record.clientId) : renderClientContacts(clientId);
    }

};
