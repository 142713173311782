import * as React from 'react';
import PropTypes from 'prop-types';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

import {BulkActionProps, Button, Identifier, useGetMany, useNotify, useUnselectAll,} from 'react-admin';
import {RESOURCE_CANDIDATES} from "../../provider/restProvider";
import {sleep} from "../../provider/UtilityFunctions";
import {useDispatch,} from "react-redux";
import {addToBasket} from "../../redux/actions/basketActions";

const noSelection: Identifier[] = [];

const AddToBasketButton: React.FC<BulkActionProps> = ({selectedIds = noSelection,}) => {
  const notify = useNotify();
  const unselectAll = useUnselectAll(RESOURCE_CANDIDATES);
  const {data, isLoading, error} = useGetMany(RESOURCE_CANDIDATES, {ids: selectedIds});

  const dispatch = useDispatch();

  const onClicked = async () => {
    while (isLoading) {
      await sleep(100);
      console.log("Waiting for getMany resource is ready:", RESOURCE_CANDIDATES);
    }
    if (!error && data.length > 0) {
      dispatch(addToBasket(RESOURCE_CANDIDATES, data));
      notify(`Added to Basket`, {type: 'info', undoable: false});
      unselectAll();
    }
  }

  return (
    <Button
        label="Add to basket"
        onClick={onClicked}
        disabled={isLoading}
    >
      <ShoppingBasketIcon/>
    </Button>
  );
};

AddToBasketButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default AddToBasketButton;
