import {
    CreateButton,
    DatagridConfigurable,
    DeleteWithConfirmButton,
    EditButton,
    Filter,
    FilterProps,
    List,
    ListProps,
    NumberField,
    TextField,
    TopToolbar
} from "react-admin";
import ActionsColumn from "../ActionsColumn";
import {ProjectEdit} from "./ProjectEdit";
import {RESOURCE_PROJECTS} from "../../provider/restProvider";
import React from "react";
import ExportButton from "../ExportButton";
import FreeTextSearchInput from "../FreeTextSearchInput";
import {PostPagination} from "../PostPagination";
import SelectColumnsButtonAutoSave from "../SelectColumnsButtonAutoSave";


// const defaultColumns: string[] = [
//     'ID',
//     'name',
//     'clientName',
//     'city',
//     'state',
//     'country',
//     'fieldManagedUpdatedName'
// ];


const ClientActions = () => (
    <TopToolbar>
        <ProjectFilter context={'button'}/>
        <CreateButton/>
        <ExportButton/>
        <SelectColumnsButtonAutoSave/>
    </TopToolbar>
);

const ProjectFilter: React.FC<Omit<FilterProps, 'children'>> = props => {
  return (
      <Filter {...props} variant={'outlined'}>
          <FreeTextSearchInput source="searchTerm" alwaysOn label={"Free Text Search"}/>
      </Filter>
  );
};

const ProjectDetail = (record) => {
  return <ProjectEdit
      resource={RESOURCE_PROJECTS} id={record.id}
      syncWithLocation={false}
  />;
};


export const ProjectList: React.FC<ListProps> = props => {

    return (
        <List title="Projects"
              {...props}
              sort={{field: 'name', order: 'ASC'}}
              filters={<ProjectFilter/>}
              actions={<ClientActions/>}
              perPage={50}
              pagination={<PostPagination/>}
        >
            <DatagridConfigurable
                rowClick="expand"
                // optimized={true}
                bulkActionButtons={false}
                expand={<ProjectDetail/>}
                expandSingle
            >
                <NumberField source="id" label={'Project ID'} sortable={true}/>
                <TextField source="name" sortable={true}/>
                <TextField source="description" sortable={true}/>
                <TextField source="clientName" sortable={false}/>
                <TextField source={"addresses[0].address"} label={'Address'} sortable={false}
                           fullWidth={true}/>
                <TextField source="addresses[0].city" label={"City/Town"} sortable={false}/>
                <TextField source="addresses[0].state" label={'State'}/>
                <TextField source="addresses[0].country" label={'Country'}/>
                <TextField source="fieldManagedUpdatedName"
                           label={'Updated by'} sortable={false}/>


                <ActionsColumn label={'Actions'}>
                    <EditButton label=""/>
                    <DeleteWithConfirmButton label=""/>
                </ActionsColumn>
            </DatagridConfigurable>

        </List>
    );
};
