import React, {useEffect, useState} from "react";
import {
  BooleanInput,
  Create,
  CreateProps,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetList,
} from "react-admin";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import {RESOURCE_CLIENTS, RESOURCE_LOOKUP_FIELDMANAGER, RESOURCE_PROJECTS,} from "../../provider/restProvider";
import {MAX_RECORD_PER_PAGE} from "../../provider/constants";
import CustomDateInput from "../CustomDateInput";
import Box from "@mui/material/Box";
import {commonStyles} from "../CommonStyles";
import {convertDateToStr} from "../../provider/UtilityFunctions";
import {useFormContext, useWatch} from "react-hook-form";
import {calculateRateWithMsp} from "./JobEdit";

const EditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton
          label="Save"
          variant="text"
          transform={(data) => {
            return {
              ...data,
              isActive: true,
        };
      }}
    />
  </Toolbar>
);

const CustomTextInput = (props) => {
  return <div></div>;
};

const classes = {
  inlineBlock: {
    display: "inline-block !important",
    marginRight: "0.5rem",
    verticalAlign: "middle",
  },
  "& .RaFormInput-input": {
    display: "inline-block !important",
  },
  raInput2: {
    marginRight: "0.5rem",
  },
  formContainer: {
    display: 'block',
    "& .ra-input": {
      display: "inline-block !important",
    },
    "& .ra-input-description": {
      width: "80% !important",
    },
    "& .ra-input-undefined": {
      marginTop: "-1.2rem",
      width: "100%",
    },
  }
};


const RenderNetBillRates = () => {
  const msp = useWatch({name: 'msp', defaultValue: 0.0});
  const straightTimeBillRate = useWatch({name: 'straightTimeBillRate', defaultValue: 0.0});
  const overtimeBillRate = useWatch({name: 'overtimeBillRate', defaultValue: 0.0});
  const doubleTimeBillRate = useWatch({name: 'doubleTimeBillRate', defaultValue: 0.0});

  return <>
    <TextField
        style={{width: "256px", marginRight: "0.5rem"}}
        label="Net ST Bill rate"
        sx={commonStyles.formComponentDefaultMargins}
        value={calculateRateWithMsp(straightTimeBillRate, msp) || 0}
        size="small"
        variant="outlined"
        fullWidth
        disabled
    />
    <TextField
        style={{width: "256px", marginRight: "0.5rem"}}
        label="Net OT Bill rate"
        sx={commonStyles.formComponentDefaultMargins}
        value={calculateRateWithMsp(overtimeBillRate, msp) || 0}
        size="small"
        variant="outlined"
        disabled={true}
        fullWidth
    />

    <TextField
        style={{width: "256px", marginRight: "0.5rem"}}
        label="Net DT Bill rate"
        sx={commonStyles.formComponentDefaultMargins}
        value={calculateRateWithMsp(doubleTimeBillRate, msp) || 0}
        disabled
        size="small"
        variant="outlined"
        fullWidth
    />
  </>;
}

const RenderStraitPayRates = () => {
  const straightTimePayRate = useWatch({name: 'straightTimePayRate', defaultValue: 0.0});
  const {setValue} = useFormContext();

  useEffect(() => {
    setValue('overtimePayRate', straightTimePayRate * 1.5);
    setValue('doubleTimePayRate', straightTimePayRate * 2.0);
  }, [straightTimePayRate]);
  return <>
    <NumberInput
        style={{width: "256px", marginRight: "0.5rem"}}
        label="Straight time pay rate"
        sx={commonStyles.formComponentDefaultMargins}
        source={'straightTimePayRate'}
        size="small"
        variant="outlined"
        fullWidth

    />
    <NumberInput
        style={{width: "256px", marginRight: "0.5rem"}}
        label="Overtime pay rate"
        sx={commonStyles.formComponentDefaultMargins}
        source={'overtimePayRate'}
        size="small"
        variant="outlined"
        disabled={true}
        fullWidth
    />

    <NumberInput
        style={{width: "256px", marginRight: "0.5rem"}}
        label="Doubletime pay rate"
        sx={commonStyles.formComponentDefaultMargins}
        source={'doubleTimePayRate'}
        size="small"
        variant="outlined"
        fullWidth
    />
  </>;
}
export const JobCreate: React.FC<CreateProps> = (props) => {

  const [clientField, setClientField] = useState("");

  const {data: clientData, isSuccess: clientLoaded} = useGetList(
      RESOURCE_CLIENTS,
      {
        pagination: {
          page: 1,
          perPage: MAX_RECORD_PER_PAGE,
        },
        sort: {field: "name", order: "ASC"}
      },
  );
  const {
    data: projectData,
    isSuccess: projectLoaded,
  } = useGetList(
      RESOURCE_PROJECTS,
      {
        pagination: {
          page: 1,
          perPage: MAX_RECORD_PER_PAGE,
        },
        sort: {field: "name", order: "ASC"},
        filter: {
          clientId: {
            fieldName: "clientId",
            fieldValue: clientField.toString(),
            condition: "==",
          },
        }
      },
  );



  return (
    <Create {...props} actions={false} >
      <SimpleForm
          warnWhenUnsavedChanges
          toolbar={
            <EditToolbar/>
          }
          style={{width: "100%"}}
          defaultValues={{
            startDate: convertDateToStr(new Date()),
            endDate: convertDateToStr(new Date()),
          }}

      >
        <Box sx={classes.formContainer}>

          <SelectInput
              variant={"outlined"}
              source="clientId"
              choices={clientLoaded ? clientData : []}
              sx={{
                ...commonStyles.formComponentDefaultMargins,
                '& div': {width: '256px'},
              }}
              onChange={(e) => setClientField(e.target.value)}
              validate={[required()]}
          />

          {clientField !== " " && (
              <SelectInput
                  variant={"outlined"}
                  source="projectId"
                  choices={
                    projectLoaded ? projectData : []
                  }
                  sx={{
                    ...commonStyles.formComponentDefaultMargins,
                    '& div': {width: '256px'},
                  }}
                  validate={[required()]}
              />
          )}
          <ReferenceInput source="fieldManagerAssignedToId" reference={RESOURCE_LOOKUP_FIELDMANAGER}>
            <SelectInput label="Field Manager" sx={commonStyles.formComponentDefaultMargins} optionText={(record) => record.name} validate={[required()]}/>
          </ReferenceInput>
          <TextInput
              source="jobTitle"
              sx={commonStyles.formComponentDefaultMargins}
              validate={[required()]}
          />
          <NumberInput
              source="numberOfPositions"
              sx={commonStyles.formComponentDefaultMargins}
              validate={[required()]}
          />
          <CustomTextInput />
          <TextInput
              source="region"
              sx={commonStyles.formComponentDefaultMargins}
              validate={[required()]}
          />
          <CustomDateInput
              source="startDate"
              sx={commonStyles.formComponentDefaultMargins}
              isRequired={true}
          />
          <CustomDateInput
              source="endDate"
              sx={commonStyles.formComponentDefaultMargins}
              isRequired={true}
          />
          <Divider style={{ minWidth: "100%", margin: "5px", height: 0 }} />

          <BooleanInput
              source="live"
              label="Job Active"
              sx={commonStyles.formComponentDefaultMargins}
          />
          <h3>Payments & Billing</h3>

          <NumberInput
              source="perDiem"
              label="Per Diem"
              sx={commonStyles.formComponentDefaultMargins}
          />
          <NumberInput
              source="msp"
              label="MSP (%)"
              max={100}
              sx={commonStyles.formComponentDefaultMargins}
          />

          <Divider style={{minWidth: '100%', marginTop: '0px', marginBottom: '15px', height: 0}}/>

          <NumberInput
              source="straightTimeBillRate"
              sx={commonStyles.formComponentDefaultMargins}
              validate={[required()]}
          />
          <NumberInput
              source="overtimeBillRate"
              sx={commonStyles.formComponentDefaultMargins}
              validate={[required()]}
          />
          <NumberInput
              source="doubleTimeBillRate"
              sx={commonStyles.formComponentDefaultMargins}
          />

          <Divider style={{minWidth: '100%', marginTop: '0px', marginBottom: '15px', height: 0}}/>

          <div style={{display: "flex", width: "100%", alignItems: "baseline"}}>
            <RenderNetBillRates/>
          </div>

          <Divider style={{minWidth: '100%', marginTop: '15px', marginBottom: '15px', height: 0}}/>

          <div style={{display: "flex", width: "100%", alignItems: "baseline"}}>
            <RenderStraitPayRates/>
          </div>

          <Divider style={{minWidth: '100%', marginTop: '15px', marginBottom: '15px', height: 0}}/>

          <TextInput
              multiline
              fullWidth={true}
              label="Description for mobile"
              source="description"
              sx={commonStyles.formComponentDefaultMargins}
              validate={[required()]}
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};
