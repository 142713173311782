import * as React from 'react';
import Popover from '@mui/material/Popover';
import HelpIcon from '@mui/icons-material/Help';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {IconButton, InputAdornment} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import {TextInputProps, useInput} from "react-admin";
import Box from "@mui/material/Box";
import grey from "@mui/material/colors/grey";


const SearchInput = (prop) => {
    const {field,} = useInput({source: prop.source});

    const [value, setValue] = React.useState(field.value || '')

    return (
        <span style={{marginBottom: 5}}>
            <div>
                <TextField
                    variant={'outlined'}
                    // name={props.input.name}
                    label={prop.label}
                    size='small'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                {
                                    <IconButton onClick={() => {
                                        field.onChange(value)
                                    }}>
                                        <SearchIcon/>
                                    </IconButton>
                                }
                            </InputAdornment>
                        )
                    }}
                    value={value}
                    onChange={(ev) => {
                        ev.persist();
                        setValue(ev.target.value);
                        if (!ev.target.value) {
                            field.onChange('')
                        }
                    }}
                    onKeyPress={(e) => {
                        e.persist();
                        if (e.key === 'Enter') {
                            field.onChange(value);
                        } else {
                            return null
                        }
                    }}
                />
            </div>
        </span>
    )
}


const FreeTextSearchInput: React.FC<TextInputProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return <Box display={'flex'} alignItems={"center"}>
        <SearchInput {...props}/>
        <div>
            <Box
                marginLeft={'10px'}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                color={grey[600]}
            >
                <HelpIcon fontSize={'medium'}/>
            </Box>

            <Popover
                id="mouse-over-popover"
                sx={{pointerEvents: 'none',}}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Box width={'250px'} padding={'5px'}>
                    <Typography
                        component="span"
                        variant={"body2"}
                        color={"textSecondary"}
                        align={'left'}
                        style={{marginBottom: '0'}}
                    >
                        Operators
                        <ul>
                            <li>AND</li>
                            <li>OR</li>
                            <li>NOT</li>
                        </ul>
                    </Typography>

                    <Typography
                        variant={"body2"}
                        color={"textSecondary"}
                        align={'left'}
                    >
                        Search is case-insensitive <br/>
                        Combine Candidate/Placement Id(s) with text:

                    </Typography>

                    <Typography
                        variant={"body2"}
                        color={"textSecondary"}
                        align={'center'}
                    >
                        21361 AND Shell AND NOT gas
                    </Typography>

                    <br/>

                    <Typography
                        variant={"body2"}
                        color={"textSecondary"}
                        align={'left'}
                    >
                        Search full dates or separately:
                        Mar/07/2022 OR ( Mar AND 2022 )
                    </Typography>

                </Box>
            </Popover>
        </div>
    </Box>;
};

export default FreeTextSearchInput;

