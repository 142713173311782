import {
    AutocompleteInput,
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    EditProps,
    NumberField,
    NumberInput,
    ReferenceManyField,
    required,
    RichTextField,
    SaveButton,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput,
    Toolbar,
    useGetList,
    useGetOne,
    useRecordContext
} from 'react-admin';
import {LazyFormTab} from "../LazyFormTab/LazyFormTab";
import {
    REF_PROJECT_JOBS,
    RESOURCE_CLIENTS,
    RESOURCE_JOBS,
    RESOURCE_LOOKUP_CITY,
    RESOURCE_LOOKUP_COUNTRY,
    RESOURCE_LOOKUP_STATE,
    RESOURCE_LOOKUP_SUBINDUSTRY,
    RESOURCE_PROJECT_COMMENTS,
    RESOURCE_PROJECT_DOCUMENTS,
    RESOURCE_PROJECT_SUB_INDUSTRIES,
    RESOURCE_PROJECTS
} from "../../provider/restProvider";
import React, {useEffect, useState} from "react";
import {UploadDocument} from "../Candidate/UploadDocument";
import DownloadButton from "../Candidate/DownloadButton";
import ActionsColumn from "../ActionsColumn";
import {MAX_RECORD_PER_PAGE} from "../../provider/constants";
import {ClientContactList} from "../ClientContacts/ClientContactList";
import LinkField from "../LinkField";
import {get} from 'lodash';
import CustomAutoCompleteLookup from "../CustomAutoComplete/CustomAutoCompleteLookup";
import Divider from "@mui/material/Divider";
import {commonStyles} from "../CommonStyles";
import {useParams} from 'react-router-dom';
import {CommentCreate} from "../CommentCreate";
import Box from "@mui/material/Box";
import {EditableDatagrid} from "@react-admin/ra-editable-datagrid";
import {LookupRowForm} from '../Candidate/CandidateCommon';
import '../CommonCss.css';

export const PROJECT_CONTACTS_TAB_IND = 2;

const EditToolbar = (props) =>
    <Toolbar {...props} >
        <SaveButton
            label="Save"
            variant="text"
        />
    </Toolbar>;

// const useStyles = makeStyles({
//   inlineBlock: {display: 'inline-block', marginRight: '0.5rem', verticalAlign: 'middle'},
//   documentType: {textTransform: 'capitalize'},
//   list: {
//     width: '100%'
//   },
//   headerCell: {
//     fontWeight: 'bold',
//   },
// });

export interface ProjectEditProps extends EditProps {
    syncWithLocation: undefined | boolean;
}

export const ProjectEdit: React.FC<ProjectEditProps> = ({syncWithLocation, ...props}) => {
    const {id} = useParams();
    const record = useRecordContext();

    const [projectId, setProjectId] = useState(id || record.id);
    const {data} = useGetOne(RESOURCE_PROJECTS, {id: id || record.id});

    const [country, setCountry] = useState(get(data, 'addresses[0].country', undefined) || 'NoCountry');
    const [state, setState] = useState(get(data, 'addresses[0].state', undefined) || 'NoState');

    useEffect(() => {
        if (data) {
            setCountry(get(data, 'addresses[0].country', undefined) || 'NoCountry');
            setState(get(data, 'addresses[0].state', undefined) || 'NoState');
            setProjectId(data.id);
        }
    }, [data]);

    const {data: countryData, isSuccess: countryLoaded} = useGetList(RESOURCE_LOOKUP_COUNTRY, {
        pagination: {
            page: 1,
            perPage: MAX_RECORD_PER_PAGE
        },
    },);

    const {data: clientData, isSuccess: clientLoaded} = useGetList(RESOURCE_CLIENTS, {
        pagination: {
            page: 1,
            perPage: MAX_RECORD_PER_PAGE
        },
        sort: {field: 'name', order: 'ASC'}
    });

    // @ts-ignore
    return (<Edit {...props} title={syncWithLocation === undefined ? '' : ' '} actions={false} mutationMode="pessimistic">
            <TabbedForm warnWhenUnsavedChanges
                        toolbar={<EditToolbar/>}
                        style={{width: '100%'}}
                        syncWithLocation={syncWithLocation === undefined ? true : syncWithLocation}
                        sx={commonStyles.tabbedFormBoldHeader}
            >

                <LazyFormTab label="Header">
                    <NumberInput source="id" label={'Project ID'}
                                 sx={commonStyles.formComponentDefaultMargins}
                                 disabled/>
                    <TextInput source="name" validate={[required()]}
                               sx={commonStyles.formComponentDefaultMargins}
                    />
                    <SelectInput
                        source="clientId"
                        validate={[required()]}
                        choices={clientLoaded ? clientData : []}
                        sx={commonStyles.formComponentDefaultMargins}
                    />

                    <TextInput source="description" fullWidth={true} validate={[required()]} multiline
                               sx={commonStyles.formComponentDefaultMargins}
                    />

                    <TextInput source={"addresses[0].address"} label={'Address'}
                               fullWidth={true}
                               sx={commonStyles.formComponentDefaultMargins}
                    />

                    <Box sx={commonStyles.flexHorizontal}>
                        <AutocompleteInput
                            validate={[required()]}
                            optionValue="name"
                            optionText="name"
                            source="addresses[0].country"
                            label={'Country'}
                            onChange={(item: any) => setCountry(item)}
                            choices={countryLoaded ? countryData : []}
                            sx={commonStyles.formComponentDefaultMargins}
                        />

                        <CustomAutoCompleteLookup
                            validate={[required()]}
                            lookupResource={RESOURCE_LOOKUP_STATE}
                            source="addresses[0].state"
                            label={"State/Province"}
                            onChange={(item: any) => setState(item)}
                            parentName={country}
                            sx={commonStyles.formComponentDefaultMargins}
                        />

                        <CustomAutoCompleteLookup
                            lookupResource={RESOURCE_LOOKUP_CITY}
                            source="addresses[0].city"
                            label={"City/Town"}
                            parentName={state}
                            sx={commonStyles.formComponentDefaultMargins}
                        />

                        <TextInput source="addresses[0].zip" label={'zip'}
                                   sx={commonStyles.formComponentDefaultMargins}
                        />
                    </Box>

                    <Divider style={{minWidth: '100%', height: 0, marginBottom: '10px', marginTop: '0'}}/>

                    <TextInput source="fieldManagerCreatedName" label={'Created by'} disabled={true}
                               sx={commonStyles.formComponentDefaultMargins}
                    />
                    <TextInput source="fieldManagedUpdatedName" label={'Updated by'} disabled={true}
                               sx={commonStyles.formComponentDefaultMargins}
                    />


                </LazyFormTab>
                <LazyFormTab label="Jobs">
                    <ReferenceManyField
                        label={''}
                        reference={RESOURCE_JOBS}
                        target={REF_PROJECT_JOBS}
                        perPage={1000}
                        fullWidth={true}
                        sort={{field: 'jobTitle', order: 'ASC'}}
                        filter={{
                            projectId: {
                                fieldName: 'projectId',
                                fieldValue: '' + projectId,
                                condition: "=="
                            },
                            IsActive: {
                                fieldName: "IsActive",
                                fieldValue: "true",
                                condition: "=="
                            }
                        }}
                    >
                        <Datagrid bulkActionButtons={false} empty={<div> No job found! </div>}>
                            <LinkField source="id" label={'Job ID'} resource={RESOURCE_JOBS}>
                                <NumberField source="id" label={'Job ID'} sortable={true}/>
                            </LinkField>
                            <TextField source="jobTitle" sortable={true}/>
                            <NumberField source="numberOfPositions" label={'Number Of Pos'} sortable={true}/>
                            <TextField source="startDate" label={'Start'} sortable={true}/>
                            <TextField source="endDate" label={'End'} sortable={true}/>
                            <TextField source="region" sortable={true}/>
                            <TextField source="clientName" label={"Client"}/>
                            <ActionsColumn>
                                <EditButton label=""/>
                                <DeleteWithConfirmButton redirect={false} label={""}/>
                            </ActionsColumn>
                        </Datagrid>
                    </ReferenceManyField>
                </LazyFormTab>
                <LazyFormTab label="Contacts">
                    {projectId && <ClientContactList projectId={projectId}/>}
                </LazyFormTab>
                <LazyFormTab label="comments">
                    <ReferenceManyField
                        reference={RESOURCE_PROJECT_COMMENTS}
                        target="comments"
                        label=""
                        perPage={100}
                        fullWidth={true}
                        sort={{field: 'lastModifiedDate', order: 'DESC'}}
                    >
                        <Datagrid bulkActionButtons={false}
                                  sx={commonStyles.subGridDefaultStyle}
                        >
                            <RichTextField source="text" label="Comment"/>
                            <RichTextField source="fieldManagerName" label="Comment Authour"/>
                            <TextField source="lastModifiedDate"/>
                            <DeleteWithConfirmButton redirect={false} label={""}/>
                        </Datagrid>
                    </ReferenceManyField>

                    <CommentCreate resource={RESOURCE_PROJECT_COMMENTS}/>
                </LazyFormTab>
                <LazyFormTab label="Documents">
                    <ReferenceManyField
                        reference={RESOURCE_PROJECT_DOCUMENTS}
                        target="documents"
                        label=""
                        perPage={100}
                        fullWidth={true}
                        sort={{field: 'name', order: 'ASC'}}
                    >
                        <Datagrid
                            bulkActionButtons={false}
                            sx={{...commonStyles.subGridDefaultStyle, width: '100%'}}
                        >
                            <TextField source="documentType" label="Type"
                                       sx={{textTransform: 'capitalize'}}
                            />
                            <TextField source="name" label="File Name"/>
                            <DateField source="creationDate"/>
                            <ActionsColumn>
                                <DownloadButton source="url" label=""/>
                                <DeleteWithConfirmButton
                                    redirect={false}
                                    label={""}/>
                            </ActionsColumn>
                        </Datagrid>
                    </ReferenceManyField>

                    <UploadDocument
                        idField={'projectId'}
                        idValue={projectId}
                        resource={RESOURCE_PROJECT_DOCUMENTS}
                        documentTypes={[{id: 'general', name: 'General'}]}
                    />
                </LazyFormTab>
                <LazyFormTab label="Industries">
                    <ReferenceManyField
                        reference={RESOURCE_PROJECT_SUB_INDUSTRIES}
                        target="subindustry"
                        label=""
                        perPage={100}
                        fullWidth={true}
                    >
                        <EditableDatagrid
                            bulkActionButtons={false}
                            className={"EditableGridBoldHeader"}
                            undoable
                            editForm={<LookupRowForm lookupResource={RESOURCE_LOOKUP_SUBINDUSTRY}/>}
                            createForm={<LookupRowForm lookupResource={RESOURCE_LOOKUP_SUBINDUSTRY}
                                                       defaultValues={{projectId: projectId}}/>}
                            noDelete={true}
                        >
                            <TextField source="name" label="Industry" sortable={false}/>
                            <DeleteWithConfirmButton redirect={false} label={""}/>
                        </EditableDatagrid>

                    </ReferenceManyField>
                </LazyFormTab>
            </TabbedForm>
        </Edit>
  );
}

